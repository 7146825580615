/*eslint-disable */
// import QuizModel from '../../Model/Quiz'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let Apps = {
  /**
   * appList
   */
  async appList (context, payloadObj = {}) {
    try{
      let post_data = new FormData();

      for (let key in payloadObj) {
        if (payloadObj[key] || typeof payloadObj[key] == 'number') {
          post_data.append(key, payloadObj[key]);
        }
      }

      return await request.curl(context, "app_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at appList() and Exception:',err.message)
    }
  },
  /**
   * appListConsole
   */
  async appListConsole (context, filter = {}) {
    try{
      let post_data = new FormData();
      post_data.append('filter', JSON.stringify(filter));
      return await request.curl(context, "app_list_console", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at appListConsole() and Exception:',err.message)
    }
  },
  /**
   * appView
   */
  async appView (context, quizId, payloadObj=null) {
    try {      
      let post_data = new FormData();
      post_data.append('app_id', quizId);

      if(payloadObj) {
        for (let key in payloadObj) {
          post_data.append(key, payloadObj[key])
        }
      }

      return await request.curl(context, "app_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at appView() and Exception:",err.message)
    }    
  },

  /**
   * appViewCustom
   */
  async appViewCustom (context, payloadObj) {
    try {      
      let post_data = new FormData();

      if (payloadObj && typeof payloadObj == 'object') {
        for (let key in payloadObj) {
          if(payloadObj[key] || typeof payloadObj[key] == 'number') {
            post_data.append(key, payloadObj[key])
          }
        }
      }

      return await request.curl(context, "app_view_custom", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at appView() and Exception:",err.message)
    }    
  },

  /**
   * appAdd
   */
  async appAdd (context, quizObj) {
    try{
      let post_data = new FormData();
    
      for (let key in quizObj) {
        if (key == "is_ques_shuffled" || key == "has_timer" || key == "show_rank" || key == "show_leaderboard" || key == "show_playagain" || key == "show_results" || key == "redirect_to_thankyou_page") {
          if (quizObj[key] == true) {
            post_data.append(key, 1);
          }
          else {
            post_data.append(key, 0);
          }
        }
        else if (quizObj[key] || typeof quizObj[key] == 'number') {
          post_data.append(key, quizObj[key]);
        }
      }

      return await request.curl(context, "app_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at appAdd() and Exception:',err.message)
    }
  },

  /**
   * appEdit
   */
  async appEdit (context, quizObj) {
    try{
      let post_data = new FormData();
    
      for (let key in quizObj) {
        if (key == "is_ques_shuffled" || key == "has_timer" || key == "show_rank" || key == "show_analysis" || key == "show_leaderboard" || key == "show_playagain" || key == "show_results" || key == "redirect_to_thankyou_page") {
          if (quizObj[key] == true) {
            post_data.append(key, 1);
          }
          else {
            post_data.append(key, 0);
          }
        }
        else if (quizObj[key] || typeof quizObj[key] == 'number') {
          post_data.append(key, quizObj[key]);
        }
      }

      return await request.curl(context, "app_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at appEdit() and Exception:',err.message)
    }
  },

  /**
   * appDelete
   */
  async appDelete (context, quizId, quizType) {
    try{
      let post_data = new FormData();
      
      post_data.append('app_id', quizId);
      post_data.append('app_type', quizType);


      return await request.curl(context, "app_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at appDelete() and Exception:',err.message)
    }
  },
  
  /**
   * appCategoryDistinct
   */
  async appCategoryDistinct (context, quizBankId = {}) {
      try {      
        let post_data = new FormData();
        return await request.curl(context, "app_category_distinct", post_data)
        .then(async response => {
          return response;
        });
      } 
      catch (err) {
        console.error("Exception occurred at appCategoryDistinct() and Exception:",err.message)
      }
    },
    
    /**
     * imageAddToApp
     */
    async imageAddToApp(context, imageObj) {
      try {
        Utility.showLoader = true;
        Utility.loadingMsg = "Uploading....";
        let post_data = new FormData();
  
        for (let key in imageObj) {
          if (key === "image") {
            if (Array.isArray(imageObj[key])) {
              //sending the image one by one because in this way it get send as file
              // if sending whole image array at once then it will get send as string of array ie '['apple', 'banana']'
              for (let index in imageObj["image"]) {
                let pic = imageObj["image"][index];
                post_data.append(pic.image_type, pic.image.img, pic.image.name);
              }
            }
            else {
              post_data.append(key, imageObj[key]);
            }
          }
          else if (key === "image_type") {
            post_data.append(key, JSON.stringify(imageObj[key]));
          }
          else {
            post_data.append(key, imageObj[key]);
  
          }
        }
        return await request.curl(context, "app_add_image_add", post_data)
        .then(async (response) => {
          Utility.showLoader = false;
          Utility.loadingMsg = "";
          return response;
        });
      }
      catch (err) {
        Utility.showLoader = false;
        Utility.loadingMsg = "";
        console.error(
          "Exception occurred at imageAddToApp() and Exception:",
          err.message
        );
      }
    },

  /**
   * appPublishUnpublish
   */
  async appPublishUnpublish (context, questionObj) {
    try{
      let post_data = new FormData();    
      for (let key in questionObj) {
        post_data.append(key, questionObj[key]);
      }
  
      return await request.curl(context, "app_publish_unpublish", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at appPublishUnpublish() and Exception:',err.message)
    }
  }
}

export {
  Apps
}
