/*eslint-disable */
// import QuestionBankModel from '../../Model/QuestionBank'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let QuizBanks = {
  /**
   * quizBankList
   */
  async quizBankList (context, quizBankObj) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";
    try{
      let post_data = new FormData();

      for (let key in quizBankObj) {
        if (quizBankObj[key]) {
          post_data.append(key, quizBankObj[key]);
        }
        else if (typeof quizBankObj[key] == 'number' && quizBankObj[key] == 0) {
          post_data.append(key, quizBankObj[key]);
        }
      }

      return await request.curl(context, "quiz_bank_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at quizBankList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * quizBankUserResponse
   */
  async quizBankUserResponse (context, quizBankObj) {
    try{
      let post_data = new FormData();

      for (let key in quizBankObj) {
        if (quizBankObj[key]) {
          post_data.append(key, quizBankObj[key]);
        }
      }

      return await request.curl(context, "quiz_bank_user_response", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at quizBankUserResponse() and Exception:',err.message)
    }
  },
  /**
   * quizBankView
   */
  async quizBankView (context, quizBankId) {
    try {      
      let post_data = new FormData();
      post_data.append('qbk_id', quizBankId);
      return await request.curl(context, "quiz_bank_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at quizBankView() and Exception:",err.message)
    }    
  },

  /**
   * quizBankAdd
   */
  async quizBankAdd (context, quizBankObj) {
    try{
      let post_data = new FormData();
    
      for (let key in quizBankObj) {
        if (quizBankObj[key] || typeof quizBankObj[key] == 'number') { // to avoid null as
          if (typeof quizBankObj[key] == "object") {
            post_data.append(key, JSON.stringify(quizBankObj[key]));
          }
          else {
            post_data.append(key, quizBankObj[key]);
          }
        }
      }

      return await request.curl(context, "quiz_bank_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at quizBankAdd() and Exception:',err.message)
    }
  },

  /**
   * quizBankEdit
   */
  async quizBankEdit (context, quizBankObj) {
    try{
      let post_data = new FormData();
    
      for (let key in quizBankObj) {
        if (typeof quizBankObj[key] == "object") {
          post_data.append(key, JSON.stringify(quizBankObj[key]));
        }
        else {
          post_data.append(key, quizBankObj[key]);
        } 
      }

      return await request.curl(context, "quiz_bank_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at quizBankEdit() and Exception:',err.message)
    }
  },

  /**
   * quizBankDelete
   */
  async quizBankDelete (context, quizBankId) {
    try{
      let post_data = new FormData();
      
      post_data.append('qbk_id', quizBankId);

      return await request.curl(context, "quiz_bank_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at quizBankDelete() and Exception:',err.message)
    }
  },

  /**
   * quizBankBulkDelete
   */
  async quizBankBulkDelete (context, quizBankDeleteObj) {
    try{
      let post_data = new FormData();
      if (quizBankDeleteObj.app_id) {
        post_data.append('app_id', quizBankDeleteObj.app_id)
      }
      else if (quizBankDeleteObj.app_category) {
        post_data.append('app_category', quizBankDeleteObj.app_category)
      }

      return await request.curl(context, "quiz_bank_bulk_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at quizBankBulkDelete() and Exception:',err.message)
    }
  },

  /**
   * appsAutomaticResultGenerate
   */
  async appsAutomaticResultGenerate (context, attempQuizObj) {
    try{
      let post_data = new FormData();
      
      for (let key in attempQuizObj) {
        if (typeof attempQuizObj[key] == "object") {
          post_data.append(key, JSON.stringify(attempQuizObj[key]));
        }
        else {
          post_data.append(key, attempQuizObj[key]);
        } 
      }

      return await request.curl(context, "apps_automatic_result_generate", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at appsAutomaticResultGenerate() and Exception:',err.message)
    }
  },

  /**
   * qbkCategory
   */
  async qbkCategory (context, quizBankId = {}) {
    try {      
      let post_data = new FormData();
      return await request.curl(context, "quiz_bank_qbk_category", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at qbkCategory() and Exception:",err.message)
    }
  },
  /**
   * imageDeleteFromQuizBank
   */
  async imageDeleteFromQuizBank (context, quizBankId) {
    try {
      Utility.showLoader = true;
      Utility.loadingMsg = "Uploading....";
      let post_data = new FormData();
      post_data.append("qbk_id", quizBankId);
      post_data.append("image", null);

      return await request.curl(context, "quiz_bank_image_remove", post_data)
      .then(async (response) => {
        Utility.showLoader = false;
        Utility.loadingMsg = "";
        return response;
      });
    }
    catch (err) {
      Utility.showLoader = false;
      Utility.loadingMsg = "";
      console.error("Exception occurred at imageDeleteFromQuizBank() and Exception:", err.message);
    }
  },
  /**
   * imageAddToQuizBank
   */
  async imageAddToQuizBank(context, imageObj) {
      try {
        Utility.showLoader = true;
        Utility.loadingMsg = "Uploading....";
        let post_data = new FormData();
  
        for (let key in imageObj) {
          if (key === "image") {
            if (Array.isArray(imageObj[key])) {
              //sending the image one by one because in this way it get send as file
              // if sending whole image array at once then it will get send as string of array ie '['apple', 'banana']'
              for (let index in imageObj["image"]) {
                let pic = imageObj["image"][index];
                post_data.append(pic.image_type, pic.image.img, pic.image.name);
              }
            }
            else {
              post_data.append(key, imageObj[key]);
            }
          }
          else if (key === "image_type") {
            post_data.append(key, JSON.stringify(imageObj[key]));
          }
          else {
            post_data.append(key, imageObj[key]);
  
          }
        }

        return await request.curl(context, "quiz_image_add_quiz_bank", post_data)
        .then(async (response) => {
          Utility.showLoader = false;
          Utility.loadingMsg = "";
          return response;
        });
      }
      catch (err) {
        Utility.showLoader = false;
        Utility.loadingMsg = "";
        console.error(
          "Exception occurred at imageAddToQuizBank() and Exception:",
          err.message
        );
      }
  },
  /**
   * quizBankSolve
   */
  async  quizBankSolve(context, solveObj) {
    try {
      Utility.showLoader = true;
      Utility.loadingMsg = "Solving....";
      let post_data = new FormData();
  
      for (let key in solveObj) {
        if (solveObj[key] || typeof solveObj[key] == 'number') { // to avoid null as
          if (typeof solveObj[key] == "object") {
            post_data.append(key, JSON.stringify(solveObj[key]));
          }
          else {
            post_data.append(key, solveObj[key]);
          }
        }
      }

      return await request.curl(context, "quiz_bank_solve", post_data)
      .then(async (response) => {
        Utility.showLoader = false;
        Utility.loadingMsg = "";
        return response;
      });
    }
    catch (error) {
      console.error("Exception in quizBankSolve and error: ", error)
    }
  }
}

export {
  QuizBanks
}
