/*eslint-disable */
// import InterestModel from '../../Model/Interest'
import request from '../../Utils/curl'

let Interests = {
  /**
   * interestList
   */
  async interestList (context, whereFilter = null, interestObj=null) {
    try{
      let post_data = new FormData();
      if (interestObj && interestObj.interest_ids_in) {
        post_data.append('interest_ids_in', interestObj.interest_ids_in);
      }
      
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "interest_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at interestList() and Exception:',err.message)
    }
  },

  /**
   * interestWithCategoryList
   */
  async interestWithCategoryList (context, interestObj, interestCat=null) {
    try{
      let post_data = new FormData();
      
      if (interestObj.page) {
        post_data.append('page', interestObj.page);
      }

      if (interestObj.do_not_load_INTEREST11117) {
        post_data.append('do_not_load_INTEREST11117', interestObj.do_not_load_INTEREST11117);
      }

      if (interestCat) {
        post_data.append('interest_cat', interestCat)
      }

      return await request.curl(context, "interest_with_category_list", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at interestWithCategoryList() and Exception:',err.message)
    }
  },

  /**
   * interestView
   */
  async interestView (context, interestId) {
    try {

      let post_data = new FormData();
      post_data.append('interest_id', interestId);
      return await request.curl(context, "interest_view", post_data)
        .then(async response => {
          return response;
        });
    }
    catch (err) {
      console.error("Exception occurred at interestList() and Exception:",err.message)
    }

  },

  /**
   * interestAdd
   */
  async interestAdd (context, interestObj) {
    try{
      let post_data = new FormData();
      for (let key in interestObj) {
        if(key === 'interest_category') {
          post_data.append('interest_category_id', interestObj['interest_category']['interest_cat_id'])
        }
        else {
          post_data.append(key, interestObj[key]);
        }
      }

      return await request.curl(context, "interest_add", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at interestAdd() and Exception:',err.message)
    }
  },

  /**
   * interestEdit
   */
  async interestEdit (context, interestObj) {
    try{
      let post_data = new FormData();

      for (let key in interestObj) {
        post_data.append(key, interestObj[key]);
      }

      return await request.curl(context, "interest_edit", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at interestEdit() and Exception:',err.message)
    }
  },

  /**
   * interestDelete
   */
  async interestDelete (context, interestId) {
    try{
      let post_data = new FormData();

      post_data.append('interest_id', interestId);

      return await request.curl(context, "interest_delete", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at interestDelete() and Exception:',err.message)
    }
  },

  /**
   * userInterestAdd
   */
  async userInterestAdd (context, userInterestObj) {
    try{
      let post_data = new FormData();
      for (let key in userInterestObj) {
        if(key === 'interest_category') {
          post_data.append('interest_category_id', interestObj['interest_category']['interest_cat_id'])
        }
        else {
          post_data.append(key, interestObj[key]);
        }
      }

      return await request.curl(context, "interest_add", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at interestAdd() and Exception:',err.message)
    }
  },
}

export {
  Interests
}
